import React, { useState, useEffect } from 'react';
import '../styles/Header.css';
import logo2 from '../img/logo/logo-crop-scroll.png';
import logo from '../img/logo/logo-crop.png';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Close menu if screen width exceeds 768px
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [menuOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getBackgroundColor = () => {
    if (scrolled) {
      return "white";
    }
    return isHomePage ? "transparent" : "#1d1d1d";
  };

  return (
    <header 
      className={`top ${scrolled ? 'scrolled' : ''} ${isHomePage ? 'home-header' : 'other-header'}`}
      style={{
        backgroundColor: getBackgroundColor(),
        transition: "background-color 0.3s ease",
      }}
    >
      <nav className="navbar">
        {/* Logo */}
        <div className="navbar-logo">
          <Link to="/">
            <img 
              src={scrolled ? logo2 : logo} 
              alt="Logo" 
              className="logo" 
            />
          </Link>
        </div>

        {/* Hamburger Menu Icon */}
        <div 
          className="menu-icon" 
          onClick={() => setMenuOpen(!menuOpen)}
        >
          ☰
        </div>

        {/* Navigation Links */}
        <div className={`navbar-right ${menuOpen ? 'menu-open' : ''}`}>
          <ul className="nav-list">
            <li className="nav-item"><a className="nav-link" href="#service">Equine Service</a></li>
            <li className="nav-item"><a className="nav-link" href="#marketplace">Marketplace</a></li>
            {/* <li className="nav-item"><a className="nav-link" href="#contact">Auction</a></li> */}
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="languageDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                English
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="languageDropdown"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#000";
                      e.target.style.color = "white";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "white";
                      e.target.style.color = "#1c482f";
                    }}
                  >
                    English
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onMouseOver={(e) => {
                      e.target.style.backgroundColor = "#000";
                      e.target.style.color = "white";
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundColor = "white";
                      e.target.style.color = "#1c482f";
                    }}
                  >
                    Arabic
                  </a>
                </li>
              </ul>
            </li> */}
          </ul>

          {/* Login/Signup */}
          <div className="login-signup">
            <span className="arrow-circle">➔</span>
            <a href="#login" className="login">Login & Signup</a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
