import React, { useEffect, useState } from "react";
import "../styles/SectionWithSlides.css";

const CustomSection = () => {

  useEffect(() => {
    const handleScroll = () => {
      const contentRight = document.querySelector(".content-right");
      if (contentRight) {
        const rect = contentRight.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          contentRight.classList.add("animate");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const benefits = [
    {
      title: "One-click Service Booking",
      description: "Book worldwide equine services in just a few clicks.",
    },
    {
      title: "Real-Time Updates & Support",
      description: "Communication with providers, available 24/7.",
    },
    {
      title: "Equine Services",
      description: "Easily manage all equine services digitally.",
    },
  ];

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="custom-section">
      <div className="image-container">
        <div className="content-left">
          <h4>For Customers</h4>
          <h1>Seamless Equestrian Services at Your Fingertips</h1>
          <p>
          Khayyal leverages cutting-edge technology to connect you with the world’s top equine service providers—all in one platform. Experience unparalleled quality, convenience, and competitive pricing.
          </p>
        </div>

        <div className="content-right-container">
         
          <div className={`content-right ${animate ? "animate" : ""}`}>
            {benefits.map((benefit, index) => (
              <div className="benefit-card" key={index}>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
          <div className="vertical-line" />
        </div>
      </div>
    </section>
  );
};

export default CustomSection;
