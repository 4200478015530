import React from 'react';
import '../styles/Dashbaord-slid.css';

import video from '../img/Final(1).mp4';

const Section1 = () => {
  return (
    <section id="section1">
        <h1 className='section1-title'>The Future of Equestrian Starts Here</h1>
        <div className="slider-content-area">
            <div className="slide-content-wrapper1" id="service">
            <div className="slide-content">
                <h1 className='big1-title'>
                Equine Services
                </h1>
                <p className='small-description1'>
                Seamlessly connecting horse owners, riders, and service providers in one powerful platform.
                </p>
                <br />
                <center><a className="default-btn1" href="#">
                View More
                </a></center>
            </div>
            <div className="section11-image">
                <video
                    src={video}
                    className="move-image"
                    autoPlay
                    loop
                    muted
                    playsInline
                />
            </div>
            </div>
        </div>
    </section>
  );
};

export default Section1;
