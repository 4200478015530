import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ser_1 from "../img/slider/ser_1.webp";
// import ser_2 from "../img/slider/ser_2.webp";
// import ser_3 from "../img/slider/ser_3.webp";
// import ser_4 from "../img/slider/ser_4.webp";
// import ser_5 from "../img/slider/ser_5.webp";
// import ser_6 from "../img/slider/ser_6.webp";
// import ser_7 from "../img/slider/ser_7.webp";

import ser_1 from "../img/slider/Trasnportation(233 x 350 px).png";
import ser_2 from "../img/slider/Stabling(233 x 350 px).png";
import ser_4 from "../img/slider/Training233 x 350 px).png";
import ser_5 from "../img/slider/Grooming (233 x 350 px).png";


import "../styles/SliderMiddlesection.css";

const SliderMiddlesection = () => {
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5, // Default number of slides
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Tablets and smaller devices
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slider-item">
          <img src={ser_1} alt="First Slide" className="slider-image" />
          <div className="overlay">
            <h3 className="title">Logistics</h3>
            <p className="description">Get connected with professionals worldwide to Ship and track your horse journey.</p>
          </div>
        </div>
        <div className="slider-item">
          <img src={ser_2} alt="Second Slide" className="slider-image" />
          <div className="overlay">
            <h3 className="title">STABLING</h3>
            <p className="description">Find Top notch facilities for all horse’s disciplines.</p>
          </div>
        </div>
        <div className="slider-item">
          <img src={ser_4} alt="Fourth Slide" className="slider-image" />
          <div className="overlay">
            <h3 className="title">Training</h3>
            <p className="description">Find Top notch Trainers for all horse’s disciplines.</p>
          </div>
        </div>
        <div className="slider-item">
          <img src={ser_5} alt="First Slide" className="slider-image" />
          <div className="overlay">
            <h3 className="title">Grooming</h3>
            <p className="description">Unrestricted access to the best horse flying and ground groomers around.</p>
          </div>
        </div>
        {/* <div className="slider-item">
          <img src={ser_3} alt="Third Slide" className="slider-image" />
          <div className="overlay">
            <h3 className="title">Veterinary</h3>
            <p className="description">Qualified veterinary experts available for Online and field visits.</p>
          </div>
        </div> */}
        {/* <div className="slider-item">
          <img src={ser_6} alt="Second Slide" className="slider-image" />
          <div className="overlay">
            <h3 className="title">SCHOOLING</h3>
            <p className="description">Browse and find the best equestrian schools near you.</p>
          </div>
        </div> */}
        {/* <div className="slider-item">
          <img src={ser_7} alt="Third Slide" className="slider-image" />
          <div className="overlay">
            <h3 className="title">Documention</h3>
            <p className="description">Find your right agent for all your equine documentation services.</p>
          </div>
        </div> */}
      </Slider>
    </div>
  );
};

export default SliderMiddlesection;
