import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from "./Dashboard.js";
import AdminPrivacyPolicy from './components/AdminPrivacyPolicy';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import AdminTermsConditions from './components/AdminTerms&Conditions.js';
import TermsConditions from './components/Terms&Conditions.js';

// Import jQuery
import $ from 'jquery';
window.$ = $; // Make jQuery globally available

function App() {
  return (
    <BrowserRouter>
      <Routes>       
        {/* Change /Dashboard to /dashboard */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/adminprivacy-policy" element={<AdminPrivacyPolicy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/adminterms-conditions" element={<AdminTermsConditions />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
