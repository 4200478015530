import React from 'react';
import '../styles/Dashbaord-slid.css';

import hors from '../img/slider/Bannerone_300190.mp4';

const Map = () => {
  return (
    <section id="mapsection">
        <div className='mapsection'> 
          <video
            src={hors}
            className="video-map"
            autoPlay
            loop
            muted
            playsInline
          />
        </div>
    </section>
  );
};

export default Map;
