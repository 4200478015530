import React, { useState } from 'react';
import '../styles/Dashbaord-slid.css';

import hors from '../img/Marketplace (500 x 333 px) (2).gif';

const Section2 = () => {
    const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const truncatedText =
    "Discover a wide selection of premium equestrian products from the most trusted brands in the industry. From riding gear to stable essentials, find everything you need at competitive prices—all in";
  
  const fullText =
    "Discover a wide selection of premium equestrian products from the most trusted brands in the industry. From riding gear to stable essentials, find everything you need at competitive prices—all in one place.";


  return (
    <section id="section2">
        <div className="slider-content-area" id="marketplace">
            <div className="slide-content-wrapper2">
                <div className="section1-image">
                    <img
                        src={hors}
                        alt="Slider Image"
                        className='move-image'
                    />
                </div>
                <div className="slide-content">
                    <h1 className='big1-title'>
                    Marketplace
                    </h1>
                    <p className='small-description1'>
                        {isExpanded ? fullText : `${truncatedText}... `}
                        <a
                            href="#"
                            className="read-more-link"
                            onClick={(e) => {
                            e.preventDefault();
                            handleToggle();
                            }}
                        >
                            {isExpanded ? " read less" : " read more"}
                        </a>
                    </p>
                    <br />
                    <center><a className="default-btn1" href="#">
                    View More
                    </a></center>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Section2;
