import React from 'react';
import '../styles/Footer.css';
import logo from '../img/logo/logo-crop.png';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="footer-container">
        <div className="footer-column">
          {/* Column 1: 4 rows */}
          <div className="footer-row">
          <div className="navbar-logo">
            <Link to="/">
              <img src={logo} alt="Logo" className="logo" />
            </Link>
          </div>
          </div>
          <div className="footer-row">
            <p>7515 al-deraya 13713-5313 2</p>
          </div>
          
          <div className="footer-row">
            <p>Beirut Street, Riyadh</p>
          </div>
          <div className="footer-row">
            <p>KSA</p>
          </div>
    
        </div>

        <div className="footer-column">
          {/* Column 2: 2 columns, 5 rows */}
          <div className="footer-row">
            <h4>General</h4>
          </div>
          <div className="footer-row">
            <p><a href="#service">Equine Service</a></p>
          </div>
          <div className="footer-row">
            <p><a href="#marketplace">Marketplace</a></p>
          </div>
          <div className="footer-row">
            <p><a href="/terms-conditions">Terms And Conditions</a></p>
          </div>
          {/* <div className="footer-row">
            <p>My Account</p>
          </div>
          <div className="footer-row">
            <p>Contact Us</p>
          </div>
          <div className="footer-row">
            <p>FAQ</p>
          </div> */}
        </div>
        <div className="footer-column">
          {/* Column 3: 3 rows */}
          <div className="footer-row">
            <h4>Follow Us</h4>
            <p>Twitter</p>
          </div>
          <div className="footer-row">
            <p>Linkedin</p>
          </div>
          <div className="footer-row">
            <p>FaceBook</p>
          </div>
          
        </div>

        <div className="footer-column">
          {/* Column 4: 3 rows */}
          <div className="footer-row">
            <h4>Contact</h4>
            <p  className="icon"><i className="fas fa-phone-alt"></i> KSA: +966 55 352 7777</p>
          </div>
          {/* <div className="footer-row">
          <p  className="icon"><i className="fas fa-phone-alt"></i> Europe: +48884951598</p>
          </div> */}
          <div className="footer-row">
          <p  className="icon"> <i className="fas fa-envelope"></i>  info@khayyal.me</p>
          </div>
        </div>
      </div>

      {/* Footer Subsection */}
<div className="footer-subsection">
<p className="footer-text">Copyright Mohr Alarabi 2025. All Rights Reserved</p>
  <div className="email-box">
    <input type="email" placeholder="Enter your email" className="email-input" />
    <button className="subsection-button">Subscribe</button>
  </div>
</div>

<hr className="footer-line" />
      {/* Footer Bottom Links */}
      <div className="footer-bottom-links">
        <div className="footer-line"></div>
        <ul className="footer-links">
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          {/* <li><a href="#">Disclaimer</a></li>
          <li><a href="#">Return Policy</a></li>
          <li><a href="#">Cookies</a></li>
          <li><a href="#">End User</a></li> */}
          <li><a href="/terms-conditions">Terms of Use</a></li>
          {/* <li><a href="#">Acceptable Use Policy</a></li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
