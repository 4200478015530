import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from "./header";
import Footer from "./Footer";

const TermsConditions = () => {
    const location = useLocation();
    const isHomePage = location.pathname === "/";

  return (
    <div>
        <Header />
        <div className={`page-content ${isHomePage ? "home-content" : ""}`}>
        <main className="container mx-auto p-4">
            <div className="about-us">
                <h2>Professional version of the Khayyal Terms and Conditions</h2>

                <h3>Terms and Conditions of Use</h3>
                
                <h4>1. Introduction</h4>
                <ul>
                    <li>These Terms and Conditions of Use ("Terms") constitute a legally binding agreement between you ("User") and Khayyal ("Company," "we," "us," or "our") regarding your access and use of our online platform that facilitates connections between Users and various equestrian clubs and service providers ("Service Providers").</li>
                </ul>

                <h4>2. Services Description</h4>
                <ul>
                    <li>Our platform offers a centralized resource for Users to discover, compare, and book equestrian experiences offered by Service Providers.</li>
                    <li>We do not own or operate any equestrian facilities. Your agreement for any equestrian services is formed directly between you and the selected Service Provider.</li>
                    <li>While we endeavor to provide up-to-date and reliable information on our platform, we cannot guarantee the absolute accuracy, completeness, or availability of Service Provider listings or their associated offerings.</li>
                </ul>

                <h4>3. User Eligibility</h4>
                <ul>
                    <li>You must be at least 18 years of age to use our platform and create an account.</li>
                    <li>Minors (individuals under the age of 18) must be accompanied by a parent or legal guardian when visiting any equestrian club booked through our platform. The accompanying adult assumes full responsibility for the minor's safety and actions.</li>
                    <li>By using our platform, you warrant that you possess the legal capacity to enter into these Terms and, if booking on behalf of a minor, are their parent or legal guardian.</li>
                    <li>By using our platform, you warrant that you possess the legal capacity to enter into these Terms.</li>
                </ul>

                <h4>4. Account Registration</h4>
                <ul>
                    <li>Certain features of our platform may necessitate the creation of a User account.</li>
                    <li>You are responsible for providing truthful, accurate, and up-to-date information during the registration process and for maintaining the confidentiality of your account credentials.</li>
                </ul>

                <h4>5. Booking Procedures</h4>
                <ul>
                    <li>Our platform enables Users to browse and initiate bookings for equestrian services directly.</li>
                    <li>All bookings are subject to the specific terms, conditions, policies, and pricing set forth by the selected Service Provider.</li>
                    <li>Payment transactions may be processed through our platform or directly with the Service Provider in accordance with their established procedures.</li>
                </ul>

                <h4>6. Cancellations and Refund Policies</h4>
                <ul>
                    <li>Each Service Provider maintains its own independent cancellation and refund policies.</li>
                    <li>Our platform will prominently display relevant policies prior to booking confirmation.</li>
                    <li>While we may assist in facilitating communications pertaining to cancellations and refunds, we do not bear direct responsibility for the issuance of refunds.</li>
                </ul>

                <h4>7. User Responsibilities</h4>
                <ul>
                    <li>You agree to utilize our platform in a lawful, ethical, and respectful manner.</li>
                    <li>You agree to refrain from engaging in any activity that could be construed as fraudulent, discriminatory, harassing, harmful, or in violation of any applicable law or regulation.</li>
                    <li>You will not infringe upon the intellectual property rights of the Company or any third party through your use of our platform.</li>
                </ul>

                <h4>8. Intellectual Property Ownership</h4>
                <ul>
                    <li>The Company retains all ownership rights to our platform, including its content, design, trademarks, and underlying technology.</li>
                    <li>You are granted a limited, non-exclusive, revocable license to access and use our platform in accordance with these Terms.</li>
                    <li>Unauthorized use of our intellectual property is strictly prohibited.</li>
                </ul>

                <h4>9. Limitation of Liability</h4>
                <ul>
                    <li>To the fullest extent permitted by law, the Company shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the platform, your interactions with Service Providers, or any third-party services accessed through our platform.</li>
                    <li>The Company makes no guarantees regarding the quality, safety, or suitability of any equestrian services offered by Service Providers.</li>
                </ul>

                <h4>10. Disclaimer of Warranties</h4>
                <ul>
                    <li>OUR PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.</li>
                </ul>

                <h4>11. Indemnification</h4>
                <ul>
                    <li>You agree to indemnify, defend, and hold harmless the Company and its affiliates, officers, directors, employees, and agents against any and all claims, liabilities, losses, damages, or expenses arising from your use of the platform or any breach of these Terms.</li>
                </ul>

                <h4>12. Booking Revision</h4>
                <ul>
                    <li>Bookings can be revised or canceled up to 48 hours before the scheduled session, subject to the club's specific policies.</li>
                    <li>Revisions made within the 48-hour window may not be accommodated, and charges may apply as per club policies.</li>
                </ul>

                <h4>13. Weight Policy</h4>
                <ul>
                    <li>For the safety of the horse and the rider, a maximum weight limit is enforced.</li>
                    <li>If a rider exceeds the weight limit, the booking may be canceled or revised, and a different horse or alternate arrangements may be suggested. Refunds for such cases will follow the platform's policy.</li>
                </ul>

                <h4>14. Local Truck Transportation</h4>
                <ul>
                    <li>Transportation services for horses are managed by the platform’s admin under a contractual agreement with the owner.</li>
                    <li>The owner must review and approve the terms and conditions related to transportation.</li>
                    <li>These terms include adherence to legal compliance and safety protocols for the transportation of horses.</li>
                </ul>

                <h4>15. Booking Steps</h4>
                <ul>
                    <li>Users must fill out the required information, including preferred date, time, and type of training or activity.</li>
                    <li>Users will be prompted to accept the Terms and Conditions before completing their booking.</li>
                    <li>Payments must be made through Tap Payments or other approved payment gateways.</li>
                </ul>

                <h4>16. Liability and Compliance</h4>
                <p>Safety and Liability:</p>
                <ul>
                    <li>Riders are required to comply with all safety guidelines and instructions provided by the club or platform.</li>
                    <li>The platform and clubs are not liable for any accidents or injuries resulting from non-compliance with safety rules.</li>
                </ul>
                <p>Legal Compliance:</p>
                <ul>
                    <li>All agreements, including bookings, transportation, and leasing, must adhere to applicable local and national regulations.</li>
                <li>Riders, horse owners, and service providers agree to comply with these regulations as part of the terms of use.</li>
                </ul>

                <h4>17. General Provisions</h4>
                <p>Amendments:</p>
                <ul>
                    <li>The platform reserves the right to update or amend the terms and conditions at any time. Users will be notified of any significant changes.</li>
                </ul>
                <p>Dispute Resolution:</p>
                <ul>
                    <li>In case of disputes, users are encouraged to contact the platform’s support team. Disputes will be resolved in accordance with the laws governing the platform’s operations.</li>
                </ul>

                <h4>18. Governing Law and Dispute Resolution</h4>
                <ul>
                    <li>These Terms shall be governed by and interpreted under the laws of KSA.</li>
                    <li>All disputes shall be resolved exclusively through binding arbitration in [Insert Jurisdiction] or another mutually agreed-upon location.</li>
                </ul>

                <h4>14. Contact</h4>
                <p>For questions or concerns, please contact: info@khayyal.com.sa</p>

            </div>
        </main>
        </div>
        <Footer />
    </div>
  );
};

export default TermsConditions;
