import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from "./header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
    const location = useLocation();
    const isHomePage = location.pathname === "/";

  return (
    <div>
        <Header />
        <div className={`page-content ${isHomePage ? "home-content" : ""}`}>
        <main className="container mx-auto p-4">
            <div className="about-us">
                <h2>Professional version of the Khayyal Privacy Policies</h2>

                <h3>1. Introduction</h3>
                <ul>
                    <li>Purpose of the Privacy Policy:<br />
                        This Privacy Policy explains how the Khayyal platform collects, uses, and protects user information when you use our services.
                        <ul>
                            <li>Emphasizes the Khayyal platform’s commitment to safeguarding user privacy.</li>
                            <li>Outlines compliance with applicable data protection laws and regulations.</li>
                        </ul>
                    </li>
                    <li>Scope of the Policy:<br />
                    Applies to all users, including riders, horse owners, clubs, and service providers interacting with the Khayyal platform.</li>
                </ul>


                <h3>2. Information We Collect</h3>
                <ul>
                    <li>
                        Personal Information:
                        <ul>
                        <li>Name, contact information (email, phone number), date of birth, and identification documents (if required).</li>
                        <li>Weight (for ensuring safety and horse compatibility).</li>
                        </ul>
                    </li>
                    <li>
                        Payment Information:
                        <ul>
                        <li>Billing address and payment details collected for processing transactions.</li>
                        </ul>
                    </li>
                    <li>
                        Booking Details:
                        <ul>
                        <li>Information related to your bookings, such as preferred time slots, training preferences, and leasing details.</li>
                        </ul>
                    </li>
                    <li>
                        Technical Information:
                        <ul>
                        <li>IP addresses, browser type, device information, and location data for Khayyal platform optimization.</li>
                        </ul>
                    </li>
                    <li>
                        Communication Data:
                        <ul>
                        <li>Messages, feedback, and inquiries submitted through the Khayyal platform.</li>
                        </ul>
                    </li>
                </ul>

                <h3>3. How We Use Collected Information</h3>
                <ul>
                    <li>
                        Service Delivery:
                        <ul>
                        <li>To process bookings, transportation requests, and leasing agreements.</li>
                        <li>To ensure rider and horse safety based on weight and booking preferences.</li>
                        </ul>
                    </li>
                    <li>
                        Payment Processing:
                        <ul>
                        <li>To securely process payments and issue receipts.</li>
                        </ul>
                    </li>
                    <li>
                        Khayyal Platform Improvement:
                        <ul>
                        <li>To analyze user behavior and improve user experience.</li>
                        </ul>
                    </li>
                    <li>
                        Marketing and Notifications:
                        <ul>
                        <li>To send promotional offers, updates about new features, and reminders for bookings.</li>
                        </ul>
                    </li>
                    <li>
                        Legal and Compliance Purposes:
                        <ul>
                        <li>To comply with applicable laws, regulations, and contractual obligations.</li>
                        <li>To resolve disputes or enforce the Khayal platform’s terms and conditions.</li>
                        </ul>
                    </li>
                </ul>

                <h3>4. Information Sharing and Disclosure</h3>
                <ul>
                    <li>
                        With Service Providers:
                        <ul>
                        <li>Information shared with equestrian clubs, trainers, and transportation companies for fulfilling booking or leasing agreements.</li>
                        </ul>
                    </li>
                    <li>
                        For Legal Requirements:
                        <ul>
                        <li>Information may be disclosed to authorities if required by law or for legal proceedings.</li>
                        </ul>
                    </li>
                    <li>
                        With User Consent:
                        <ul>
                        <li>Information shared with third parties only with explicit user consent (e.g., for marketing partnerships).</li>
                        </ul>
                    </li>
                    <li>
                        Data Anonymization:
                        <ul>
                        <li>Non-identifiable data may be shared for research or analytical purposes.</li>
                        </ul>
                    </li>
                </ul>

                <h3>5. Data Security</h3>
                <ul>
                    <li>
                        Security Measures:
                        <ul>
                        <li>Encryption and secure storage of personal and payment information.</li>
                        <li>Regular audits and monitoring to prevent unauthorized access or data breaches.</li>
                        </ul>
                    </li>
                    <li>
                        User Responsibility:
                        <ul>
                        <li>Users are responsible for maintaining the confidentiality of their login credentials.</li>
                        </ul>
                    </li>
                </ul>

                <h3>6. User Rights</h3>
                <ul>
                    <li>
                        Access and Correction:
                        <ul>
                        <li>Users can access and update their personal information through their account settings.</li>
                        </ul>
                    </li>
                    <li>
                        Data Deletion:
                        <ul>
                        <li>Users can request account deletion or removal of specific data by contacting customer support.</li>
                        </ul>
                    </li>
                    <li>
                        Opt-Out Options:
                        <ul>
                        <li>Users can opt out of marketing communications and notifications.</li>
                        </ul>
                    </li>
                    <li>
                        Data Portability:
                        <ul>
                        <li>Users can request a copy of their data in a portable format, where applicable.</li>
                        </ul>
                    </li>
                </ul>

                <h3>7. Cookies and Tracking Technologies</h3>
                <ul>
                    <li>
                        Use of Cookies:
                        <ul>
                        <li>Cookies are used to enhance user experience, remember preferences, and track usage patterns.</li>
                        </ul>
                    </li>
                    <li>
                        User Control:
                        <ul>
                        <li>Users can manage cookie preferences through their browser settings.</li>
                        </ul>
                    </li>
                </ul>

                <h3>8. Third-Party Links</h3>
                <ul>
                    <li>The Khayyal platform may include links to third-party websites or services</li>
                    <li>The Khayyal platform is not responsible for the privacy practices or content of these external sites.</li>
                </ul>
                    
                <h3>9. Children’s Privacy</h3>
                <ul>
                    <li>The Khayyal platform does not knowingly collect personal information from individuals under the age of 18 without parental consent.</li>
                </ul>

                <h3>10. Policy Updates</h3>
                <ul>
                    <li>
                        Notification of Changes:
                        <ul>
                        <li>Any significant changes to this Privacy Policy will be communicated via email or Khayyal platform notifications.</li>
                        </ul>
                    </li>
                    <li>
                        Effective Date:
                        <ul>
                        <li>The policy’s effective date will be indicated at the top of the document.</li>
                        </ul>
                    </li>
                </ul>

                <h3>11. Contact Information</h3>
                <ul>
                    <li>Users can contact the Khayyal platform’s Data Protection Officer or support team for any privacy-related concerns or queries.
                        <ul>
                            <li>Email Address: Info@khayyal.me</li>
                            <li>Phone Number: +966 55 352 7777</li>
                        </ul>
                    </li>
                </ul>

            </div>
        </main>
        </div>
        <Footer />
    </div>
  );
};

export default PrivacyPolicy;
