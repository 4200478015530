import './styles/Dashboard.css';
import 'react-awesome-slider/dist/styles.css'; 
import Header from './components/header.js'; 
import SliderSection from './components/Slidersection.js';
import Section1 from './components/section1.js';
import Section2 from './components/section2.js';
import Footer from './components/Footer.js';
import SliderMiddlesection from './components/SliderMiddlesection.js';
import CustomSection from './components/CustomSection.js';
import FindWaySection from './components/FindYourWay.js';
import Map from './components/Map.js';
import MobileApp from './components/MobileApp.js';


const Dashboard = () => {
 

  return (
    <div className="dashboard-container">
      <Header />
      <SliderSection/>
      <Section1/>
      <SliderMiddlesection/>
      <Section2/>
      <FindWaySection/>
      <CustomSection/>
      <Map/>
      <Footer/>
    </div>
  );
};

export default Dashboard;