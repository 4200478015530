import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from "./header";
import Footer from "./Footer";

const AdminPrivacyPolicy = () => {
    const location = useLocation();
    const isHomePage = location.pathname === "/";

  return (
    <div>
        <Header />
        <div className={`page-content ${isHomePage ? "home-content" : ""}`}>
        <main className="container mx-auto p-4">
            <div className="about-us">
                <h2>Professional version of the Khayyal Admin Privacy Policies</h2>

                <h3>1. Information We Collect</h3>
                <p>
                    We may collect the following information from Equestrian Clubs during registration, profile creation, and ongoing use of the Khayyal platform:
                </p>

                <h4>1.1 <b>Business Information</b></h4>
                <ul>
                    <li>Commercial Registration (CR) details.</li>
                    <li><span>Legal and financial documents for verification (optional?).</span></li>
                    <li>Equestrian club name, logo, address, services provided in club and contact details.</li>
                </ul>

                <h4>1.2 <b>Event Information</b></h4>
                <ul>
                    <li>Event details, calendars, and ticketing data.</li>
                </ul>

                <h4>1.3 <b>Usage Information</b></h4>
                <ul>
                    <li>Data on how the Khayyal platform is used, including features accessed, events published, and marketing activities conducted.</li>
                </ul>

                <h4>1.4 <b>Technical Information</b></h4>
                <ul>
                    <li>IP address, browser type, device information, and cookies.</li>
                </ul>

                <h3>2. How We Use Your Information</h3>
                <p>We use your information for the following purposes:</p>

                <h4>2.1 <b>Account Management</b></h4>
                <ul>
                    <li>To verify and approve equestrian clubs during the onboarding process.</li>
                    <li>To maintain and update your profile information.</li>
                </ul>

                <h4>2.2 <b>Service Delivery</b></h4>
                <ul>
                    <li>To enable event publishing, ticketing, and marketing features.</li>
                    <li>To communicate important updates, announcements, and support services.</li>
                </ul>

                <h4>2.3 <b>Khayyal Platform Improvement</b></h4>
                <ul>
                    <li>To analyze usage data and enhance Khayyal platform functionality.</li>
                </ul>

                <h4>2.4 <b>Legal Compliance</b></h4>
                <ul>
                    <li>To ensure compliance with applicable laws and regulations.</li>
                </ul>

                <h4>2.5 <b>Marketing and Promotions</b></h4>
                <ul>
                    <li>To offer tailored promotional campaigns for events or services.</li>
                </ul>

                <h3>3. Sharing Your Information</h3>
                <p>We may share your information with:</p>

                <h4>3.1 <b>Authorized Parties</b></h4>
                <ul>
                    <li>Users of the Khayyal platform, such as customers or event attendees, to facilitate bookings, ticket sales, or other interactions.</li>
                </ul>

                <h4>3.2 <b>Service Providers</b></h4>
                <ul>
                    <li>Third-party vendors providing payment processing, marketing tools, or technical support.</li>
                </ul>

                <h4>3.3 <b>Legal Authorities</b></h4>
                <ul>
                    <li>Government or regulatory bodies when required by law.</li>
                </ul>

                <h4>3.4 <b>Business Transfers</b></h4>
                <ul>
                    <li>In the event of a merger, acquisition, or sale of assets, your information may be transferred to the acquiring entity.</li>
                </ul>

                <h3>4. Data Security</h3>
                <p>4.1 We implement robust security measures to protect your information, including encryption, secure servers, and access controls.</p>
                <p>4.2 Despite our efforts, no system is completely secure. By using the Khayyal platform, you acknowledge the inherent risks of data transmission.</p>

                <h3>5. Your Privacy Rights</h3>
                <p>5.1 You have the right to access, update, or delete your information by contacting our support team or through your account settings.</p>
                <p>5.2 You may opt-out of marketing communications by updating your preferences in the Khayyal platform settings.</p>

                <h3>6. Cookies and Tracking Technologies</h3>
                <p>6.1 The Khayyal platform uses cookies to enhance user experience, track usage, and personalize content.</p>
                <p>6.2 You may disable cookies through your browser settings, though some features may not function as intended.</p>

                <h3>7. Data Retention</h3>
                <p>7.1 We retain your information for as long as necessary to provide platform services, comply with legal obligations, and resolve disputes.</p>
                <p>7.2 Upon termination of your account, we may retain limited data for record-keeping and compliance purposes.</p>

                <h3>8. Third-Party Links</h3>
                <p>The Khayyal platform may contain links to third-party websites. We are not responsible for the privacy practices or content of those sites.</p>

                <h3>9. Children’s Privacy</h3>
                <p>The Khayyal Platform is not intended for use by individuals under the age of 18.</p>

                <h3>10. International Data Transfers</h3>
                <p>If you are accessing the Khayyal platform from outside Saudi Arabia, your data may be transferred to and processed in Saudi Arabia, subject to local privacy laws.</p>

                <h3>11. Policy Updates</h3>
                <p>We may update this Privacy Policy to reflect changes in our practices or applicable laws. You will be notified of significant changes through the Khayyal platform or via email.</p>

                <h3>12. Contact Us</h3>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>

                <h3>Khayyal Platform Support Team</h3>
                <p>
                    <b>Email:</b> support@khayyal.me <br />
                    <b>Phone:</b> +966-55-352-7777 <br />
                    By registering on and using the Khayyal Platform, you agree to the terms of this Privacy Policy. <br />
                    <b>Last Updated:</b> 19/02/2025
                </p>

            </div>
        </main>
        </div>
        <Footer />
    </div>
  );
};

export default AdminPrivacyPolicy;
