import React from 'react';
import Slider from 'react-slick';
import '../styles/Dashbaord-slid.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//import hors from '../img/slider/slide-1.png';
//import horsvideo from '../img/slider/bannerSlide2.mov';
import backgroundImage from '../img/shape/1900x1000.png';  
//import beach from '../img/beach.png';
import banner1 from '../img/slider/Bannerone_300190.mp4';
import banner2 from '../img/slider/banner2 350.png';
import banner3 from '../img/slider/banner3 (500 x 256 px).mp4';

const SliderSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,  
  };

  const sliderItems = [
    {
      backgroundImage,
      //img: [hors],
      video: [banner1],
      title: 'Where Tradition Meets Innovation',
      description: 'The Khayyal Equestrian Hub: Ride, Train, Trade & Transport!',
      buttonLink: '#',
    },
    {
      backgroundImage,
      //video: [horsvideo],
      img: [banner2],
      title: 'Bridging the Equestrian Community with Smart Technology',
      description:
        'Join the Khayyal platform to effortlessly find, book, and manage all your equestrian services—from training to veterinary care—all under one roof.',
      buttonLink: '#',
    },
    {
      backgroundImage,
      //img: [beach],
      video: [banner3],
      title: 'Elevate Your Equestrian Experience',
      description:
        'Connecting global equestrian service providers with horse owners, individuals, freelancers, and businesses—ensuring seamless accessibility and availability for all equine needs.',
      buttonLink: '#',
    },
    
    // Add more items as needed
  ];

  return (
    <section id="section0" className="slider-area">
      <Slider {...settings}>
        {sliderItems.map((item, index) => (
          <div
            key={index}
            className="single-slide item"
            style={{ backgroundImage: `url(${item.backgroundImage})` }}
          >
            <div className="slider-content-area">
              <div className="slide-content-wrapper">
                <div className="slide-content">
                  <h1
                    className={index === 0 ? 'small-title' : 'big-title'}
                  >
                    {item.title}
                  </h1>
                  <p
                    className={index === 0 ? 'big-description' : 'small-description'}
                  >
                    {item.description}
                  </p>
                  <br />
                  <a className="default-btn" href={item.buttonLink}>
                    View More
                  </a>
                </div>
                <div className="slide-image">
                  {item.video ? (
                    <video
                      src={item.video}
                      className="video-slide"
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                  ) : (
                    <img
                      src={item.img}
                      alt="Slider"
                      className={index === 0 ? 'move-image' : 'small-image'}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default SliderSection;
