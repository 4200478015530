import React, { useEffect, useState } from "react";
import "../styles/SectionWithSlides.css";

const FindYourWay = () => {

  useEffect(() => {
    const handleScroll = () => {
      const contentRight = document.querySelector(".content-right");
      if (contentRight) {
        const rect = contentRight.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          contentRight.classList.add("animate");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const benefits = [
    {
      title: "Unique Customer Support",
      description: "Instant call & video support, available 24/7.",
    },
    {
      title: "Multi-Currency Support",
      description: "Accept payments in SAR",
    },
    {
      title: "Quick Service Addition",
      description: "Add new services in just 2-3 clicks.",
    },
  ];

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 300);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="custom-section">
      <div className="image-container1">
        <div className="content-left">
          <h4>For Service Providers</h4>
          <h1>Manage Your Business Effortlessly</h1>
          <p>
          Khayyal empowers service providers with a dedicated control panel to efficiently manage their operations, saving time and effort while delivering exceptional service to customers.
          </p>
        </div>

        <div className="content-right-container">
         
          <div className={`content-right ${animate ? "animate" : ""}`}>
            {benefits.map((benefit, index) => (
              <div className="benefit-card" key={index}>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </div>
            ))}
          </div>
          <div className="vertical-line" />
        </div>
      </div>
    </section>
  );
};

export default FindYourWay;
