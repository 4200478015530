import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from "./header";
import Footer from "./Footer";

const AdminTermsConditions = () => {
    const location = useLocation();
    const isHomePage = location.pathname === "/";

  return (
    <div>
        <Header />
        <div className={`page-content ${isHomePage ? "home-content" : ""}`}>
        <main className="container mx-auto p-4">
            <div className="about-us">
                <h2>Professional version of the Khayyal Admin Terms and Conditions</h2>

                <h3>1. Registration and Profile Completion</h3>
                <p>1.1 The Club Owner may register on the Khayyal Platform to access its services and features.</p>
                <p>1.2 Profile completion requires uploading:</p>
                <ul>
                    <li>A valid Commercial Registration (CR) document.</li>
                    <li>Legal documents, such as ownership or operating licenses.</li>
                    <li>Financial documents as necessary for verification.<br />1.3 The Club Owner agrees to provide accurate, up-to-date information and maintain the integrity of the profile.</li>
                </ul>

                <h3>2. Digital Agreement Enablement</h3>
                <p>2.1 The Club Owner must agree to these Terms through a <b>digital signature</b> mechanism provided on the Khayyal Platform.</p>
                <p>2.2 A legally binding agreement between the Club Owner and Khayyal is activated upon signing.</p>

                <h3>3. Equestrian Events and Calendar</h3>
                <p>3.1 Khayyal provides an events calendar listing relevant equestrian events, competitions, and activities.</p>
                <p>3.2 The Club Owner can:</p>
                <ul>
                    <li>Publish their own events on the Khayyal platform.</li>
                    <li>Access and participate in events listed by others.</li>
                </ul>

                <h3>4. Marketing and Promotions</h3>
                <p>4.1 The Club Owner may promote their equestrian events, ticketed activities, and other services using Khayyal’s marketing tools.</p>
                <p>4.2 Marketing campaigns are subject to Khayyal’s review and approval to ensure compliance with platform standards.</p>

                <h3>5. Ticketing Services</h3>
                <p>5.1 The Club Owner may sell tickets for events through the Khayyal platform.</p>
                <p>5.2 Ticket sales are processed through Khayyal’s payment gateway, with applicable service fees agreed upon prior to usage.</p>

                <h3>6. Messaging and Communication</h3>
                <p>6.1 The Club Owner can communicate with Khayyal for support, inquiries, or feedback directly through the platform's messaging feature.</p>

                <h3>7. Use of Uploaded Documents</h3>
                <p>7.1 All uploaded documents, including CR, legal, and financial information, are stored securely in compliance with privacy and data protection laws.</p>
                <p>7.2 Documents are used solely for verification and onboarding purposes and will not be shared with third parties without prior consent.</p>

                <h3>8. Fees and Payment</h3>
                <p>8.1 Fees for using Khayyal’s services, including marketing or ticketing, will be detailed in a separate pricing document.</p>
                <p>8.2 The Club Owner is responsible for paying applicable fees in a timely manner, should we add the split payments here?</p>

                <h3>9. Intellectual Property</h3>
                <p>9.1 Khayyal retains ownership of all platform designs, features, and materials.</p>
                <p>9.2 The Club Owner is granted a limited license to use the platform’s features for their intended purpose.</p>

                <h3>10. Prohibited Activities</h3>
                <p>10.1 The Club Owner agrees not to:</p>
                <ul>
                    <li>Misuse or exploit the platform for unlawful activities.</li>
                    <li>Publish false or misleading information about their services or events.</li>
                </ul>

                <h3>11. Termination of Services</h3>
                <p>11.1 Either party may terminate this agreement with 30 days' written notice.</p>
                <p>11.2 Upon termination, the Club Owner’s access to the platform’s features will be revoked, and all pending obligations must be fulfilled.</p>

                <h3>12. Liability and Indemnity</h3>
                <p>12.1 Khayyal is not liable for any damages resulting from misuse of the platform or events listed by the Club Owner.</p>
                <p>12.2 The Club Owner agrees to indemnify Khayyal against any legal claims arising from their activities on the platform.</p>

                <h3>13. Privacy Policy</h3>
                <p>13.1 The use of Khayyal is subject to its Privacy Policy, which outlines the handling of personal data and compliance with applicable laws.</p>

                <h3>14. Amendments</h3>
                <p>14.1 Khayyal reserves the right to amend these Terms at any time, with prior notification to the Club Owner. Continued use of the platform constitutes acceptance of revised Terms.</p>

                <h3>15. Governing Law and Dispute Resolution</h3>
                <p>15.1 These Terms are governed by the laws of the Kingdom of Saudi Arabia.</p>
                <p>15.2 Any disputes will be resolved through arbitration in accordance with local legal provisions.</p>

            </div>
        </main>
        </div>
        <Footer />
    </div>
  );
};

export default AdminTermsConditions;
